import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import MainPage from "./mainPage";
import reportWebVitals from "./reportWebVitals";
import MediaQueryProvider from "./contexts/MediaQueryContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MediaQueryProvider>
      <MainPage />
    </MediaQueryProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
